import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { useFetch } from "../hooks/useFetch";
import { loadTossPayments } from "@tosspayments/tosspayments-sdk";
import { SimpleCartTable } from "../components/common/Cart";
import { v4 as uuidv4 } from 'uuid';


const OrderDetailForm = ({ userOrderData, setUserOrderData }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const handlePostcodeSearch = () => {
        new window.daum.Postcode({
            oncomplete: function (data) {
                const roadAddr = data.roadAddress; // 도로명 주소
                const jibunAddr = data.jibunAddress; // 지번 주소
                const zonecode = data.zonecode; // 우편번호

                setUserOrderData((prevData) => ({
                    ...prevData,
                    address: roadAddr || jibunAddr, // 도로명 주소를 우선, 없으면 지번 주소
                    postCode: zonecode, // 우편번호 설정
                }));
            }
        }).open();
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserOrderData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <div>
            <div className="border-b-2 border-gray-800 py-5 ml-3 font-bold text-xl">
                배송 정보
            </div>
            <div className="flex flex-col mt-10">
                {/* 주문자 */}
                <div className="flex flex-row items-center justify-between pl-5 my-4">
                    <div className="w-1/4 font-bold text-lg">주문자</div>
                    <input
                        type="text"
                        name="recipientName"
                        value={userOrderData.recipientName || ""} 
                        onChange={handleInputChange}
                        className="w-2/3 p-2 border border-gray-300"
                        placeholder="주문자"
                    />
                </div>

                {/* 전화번호 */}
                <div className="flex flex-row items-center justify-between pl-5 my-4">
                    <div className="w-1/4 font-bold text-lg">전화번호</div>
                    <input
                        type="text"
                        name="phoneNum"
                        value={userOrderData.phoneNum || ""} 
                        onChange={handleInputChange}
                        className="w-2/3 p-2 border border-gray-300"
                        placeholder="전화번호"
                    />
                </div>

                {/* 이메일 */}
                <div className="flex flex-row items-center justify-between pl-5 my-4">
                    <div className="w-1/4 font-bold text-lg">이메일</div>
                    <input
                        type="text"
                        name="email"
                        value={userOrderData.email || ""} 
                        onChange={handleInputChange}
                        className="w-2/3 p-2 border border-gray-300"
                        placeholder="이메일"
                    />
                </div>

                {/* 주소 */}
                <div className="flex flex-row items-center justify-between pl-5 my-4">
                    <div className="w-1/4 font-bold text-lg">주소</div>
                    <div className="flex w-2/3 items-center space-x-2">
                        <input
                            type="text"
                            name="address"
                            value={userOrderData.address || ""} 
                            readOnly
                            className="w-full p-2 border border-gray-300"
                            placeholder="주소"
                        />
                        <button 
                            type="button" 
                            onClick={handlePostcodeSearch}
                            className="flex-shrink-0 px-4 py-2 bg-gray-800 text-white rounded">
                            주소검색
                        </button>
                    </div>
                </div>

                {/* 우편번호 */}
                <div className="flex flex-row items-center justify-between pl-5 my-4">
                    <div className="w-1/4 font-bold text-lg">우편번호</div>
                    <input
                        type="text"
                        name="postCode"
                        value={userOrderData.postCode || ""} 
                        readOnly
                        className="w-2/3 p-2 border border-gray-300"
                        placeholder="우편번호"
                    />
                </div>

                {/* 상세 주소 */}
                <div className="flex flex-row items-center justify-between pl-5 my-4">
                    <div className="w-1/4 font-bold text-lg">상세주소</div>
                    <input
                        type="text"
                        name="detailAddress"
                        value={userOrderData.detailAddress || ""} 
                        onChange={handleInputChange}
                        className="w-2/3 p-2 border border-gray-300"
                        placeholder="상세주소"
                    />
                </div>

                {/* 요청사항 */}
                <div className="flex flex-row items-center justify-between pl-5 my-4">
                    <div className="w-1/4 font-bold text-lg">요청사항</div>
                    <input
                        type="text"
                        name="requirements"
                        value={userOrderData.requirements || ""} 
                        onChange={handleInputChange}
                        className="w-2/3 p-2 border border-gray-300"
                        placeholder="요청사항"
                    />
                </div>
            </div>
        </div>
    );
};

const CouponSelector = ({ ownedCoupons, selectedCoupon, setSelectedCoupon }) => {
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(dateString).toLocaleDateString('ko-KR', options);
    };

    const getDiscountInfo = (coupon) => {
        return coupon.type === 'PRICE' ? `${coupon.discount_price}원 할인` : `${coupon.discount_ratio}% 할인`;
    };

    return (
        <div className="p-6">
            <div className="flex justify-between items-center border-b-2 border-gray-800 py-5 mb-10 font-bold text-xl">
                보유 쿠폰 ({ownedCoupons.length}장)
            </div>

            <div className="grid grid-cols-2 gap-4">
                {ownedCoupons.length > 0 ? (
                    ownedCoupons.map((coupon) => (
                        <div
                            key={coupon.coupon_id}
                            onClick={() => setSelectedCoupon(selectedCoupon && selectedCoupon.coupon_id === coupon.coupon_id ? null : coupon)}
                            className={`p-5 border-2 rounded-lg cursor-pointer transition-transform duration-300
                                ${selectedCoupon?.coupon_id === coupon.coupon_id ? 'border-slate-800 bg-slate-100' : 'border-gray-300'}
                                hover:shadow-md`}
                            style={{ minHeight: '120px' }} // 고정 높이 설정
                        >
                            <h4 className="font-bold text-lg">{coupon.name}</h4>
                            <p className="text-gray-500">{getDiscountInfo(coupon)}</p>
                            <p className="text-sm text-gray-400">
                                {formatDate(coupon.started_at)} ~ {formatDate(coupon.finished_at)}
                            </p>
                            <div className="mt-2 text-sm text-green-600 font-semibold" style={{ visibility: selectedCoupon?.coupon_id === coupon.coupon_id ? 'visible' : 'hidden' }}>
                                선택됨
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="col-span-2 text-gray-500 text-center">
                        보유한 쿠폰이 없습니다.
                    </p>
                )}
            </div>
        </div>
    );
};

const Order = () => {
    const { api } = useFetch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { _cartData, orderType } = state;
    const [cartData, setCartData] = useState(_cartData);
    const [userOrderData, setUserOrderData] = useState({
        item: [],
        address: "",
        detailAddress: "",
        phoneNum: "",
        requirements: "",
        recipientName: "",
        postCode: "",
        merchantId: "",
        totalPrice: "",
    });
    const [ownedCoupons, setOwnedCoupons] = useState([]);
    const [selectedCoupon, setSelectedCoupon] = useState(null);

    const paymentMethodWidgetRef = useRef(null); // 결제수단 위젯 참조
    const paymentWidgetRef = useRef(null); // 결제 위젯 전체 참조

    useEffect(() => {
        window.scroll(0, 0)
    }, []);

    useEffect(() => {
        const fetchCoupons = async () => {
            const response = await api({ action: "getUserCoupon" });
            if (!response.error) setOwnedCoupons(response.data);
        };
        fetchCoupons();
    }, []);

    useEffect(() => {
        const initializeWidget = async () => {
            try {
                let data = null;
    
                if (orderType === 'cart') {
                    const response = await api({
                        action: "initialCartOrder",
                        params: {
                            type: "cart",
                            cartId: cartData.map(item => item.cart_id),
                        },
                    });
                    data = response.data;
                } else if (orderType === 'item') {
                    const options = cartData.map(item => ({
                        item_id: item.item_id,
                        option: item.options,
                        quantity: item.quantity
                    }));
    
                    const response = await api({
                        action: "initialItemOrder",
                        params: {
                            type: "item",
                            options: options,
                        },
                    });
                    data = response.data;
                }
    
                if (!data) {
                    console.error('No data returned from API');
                    return;
                }
    
                setUserOrderData({
                    item: data.ordering_item,
                    address: data.address,
                    detailAddress: data.detail_address,
                    phoneNum: data.phone_num,
                    requirements: data.requirements,
                    recipientName: data.recipientName,
                    postCode: data.post_code,
                    totalPrice: data.total_price,
                    merchantId: data.merchant_id,
                });
    
                if (!paymentWidgetRef.current) {
                    const tossPayments = await loadTossPayments(process.env.REACT_APP_TOSS_CLIENT_KEY);
                    const customerKey = uuidv4();
    
                    const widgets = tossPayments.widgets({ customerKey });
    
                    widgets.setAmount({
                        currency: 'KRW',
                        value: data.total_price,
                    });
    
                    const paymentMethodWidget = await widgets.renderPaymentMethods({
                        selector: "#payment-widget",
                        variantKey: "DEFAULT",
                    });
    
                    await widgets.renderAgreement({
                        selector: "#agreement",
                        variantKey: "DEFAULT",
                    });
    
                    paymentMethodWidgetRef.current = paymentMethodWidget;
                    paymentWidgetRef.current = widgets;
                }
            } catch (err) {
                console.error('Error initializing widget:', err);
            }
        };
    
        initializeWidget();
    }, [cartData, orderType]);
    

    useEffect(() => {
        // 쿠폰 선택 시 결제 금액 업데이트
        const discountedPrice = getDiscountedPrice();
        if (paymentWidgetRef.current) {
            paymentWidgetRef.current.setAmount({ currency: 'KRW', value: discountedPrice });
        }
    }, [selectedCoupon]);

    const getDiscountedPrice = () => {
        const totalPrice = userOrderData.totalPrice;
        if (selectedCoupon) {
            return selectedCoupon.type === 'PRICE'
                ? Math.max(0, totalPrice - selectedCoupon.discount_price)
                : Math.max(0, totalPrice * (1 - selectedCoupon.discount_ratio / 100));
        }
        return totalPrice;
    };

    const onPurchase = async () => {
        try {
            const selectedPaymentMethod = await paymentMethodWidgetRef.current.getSelectedPaymentMethod();
            const discountedPrice = getDiscountedPrice();

            const { data, error } = await api({
                action: "setOrder",
                params: {
                    itemInfo: userOrderData.item,
                    merchantId: userOrderData.merchantId,
                    recipientName: userOrderData.recipientName,
                    postCode: userOrderData.postCode,
                    address: userOrderData.address,
                    detailAddress: userOrderData.detailAddress,
                    phoneNum: userOrderData.phoneNum,
                    requirements: userOrderData.requirements,
                    paymentMethod: selectedPaymentMethod.code,
                    totalPrice: discountedPrice,
                    couponId: selectedCoupon ? selectedCoupon.coupon_id : ""
                }
            });

            if (!error) {
                await api({
                    action: "setPayment",
                    params: {
                        paymentMethod: selectedPaymentMethod.code,
                        paymentName: `${userOrderData.item[0]} 외 ${userOrderData.item.length}건`,
                        merchantId: userOrderData.merchantId,
                        amount: discountedPrice,
                    }
                });
            }
        } catch (err) {
            console.error(err);
        }

        try {
            if (paymentWidgetRef.current) {
                await paymentWidgetRef.current.requestPayment({
                    orderId: userOrderData.merchantId,
                    orderName: `${userOrderData.item[0]} 외 ${userOrderData.item.length}건`,
                    customerName: userOrderData.recipientName,
                    successUrl: `${window.location.origin}/order/success`,
                    failUrl: `${window.location.origin}/order/fail`,
                });
            }
        } catch (err) {
            console.error('결제 요청 중 오류가 발생했습니다:', err);
        }
    };

    return (
        <div className="flex flex-row justify-between 2xl:w-4/5 xl:w-4/5 w-[95%] h-full pt-28 mx-auto pb-48 font-nanum">
            <div className="flex flex-col justify-start w-[60%] ">
                <div className="flex flex-col">
                    <div className="py-5 ml-3 font-bold text-3xl">Order</div>
                    <SimpleCartTable cartData={cartData} />
                    
                    {orderType === 'cart' && 
                    <div className="flex flex-row justify-end">
                        <button
                            className="flex flex-row justify-center px-4 py-2 mt-4 mr-1 bg-gray-300 rounded cursor-pointer"
                            onClick={() => navigate('/mypage#cart')}
                        >
                            상품수정
                        </button>
                    </div>}

                </div>
                <div className="flex flex-col mt-32">
                    <OrderDetailForm userOrderData={userOrderData} setUserOrderData={setUserOrderData} />
                </div>

                <div className="flex flex-col mt-32">
                    <CouponSelector 
                        ownedCoupons={ownedCoupons}
                        selectedCoupon={selectedCoupon}
                        setSelectedCoupon={setSelectedCoupon}
                    />
                </div>
                    
            </div>

            <div className="flex flex-col w-[35%] min-w-[330px] h-[40%] border-[1.8px] border-gray-600 py-5 ml-3 mt-10 sticky top-40">
                <div className="flex justify-between font-bold mx-4 py-3">
                    <div>총 주문 금액</div>
                    <div>
                        {getDiscountedPrice().toLocaleString('ko-KR')} 원
                    </div>
                </div>
                <hr className="border-[0.5px] border-zinc-800 mx-3 my-4" />
                <div id="payment-widget" className="mx-4 pb-3"></div>
                <div id="agreement" className="mx-4 pb-3"></div>
                <div className="flex justify-center mt-5 mx-4">
                    <button 
                        className="w-[90%] h-12 bg-gray-800 text-white rounded"
                        onClick={onPurchase}
                    >
                        결제하기
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Order;
