import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../hooks/useFetch";

const FREE_SHIPPING_THRESHOLD = 0;
const SHIPPING_COST = 3000; // 배송비 3,000원

const Cart = () => {
    const navigate = useNavigate();
    const { api } = useFetch();
    const [isLoading, setIsLoading] = useState(true);
    const [cartData, setCartData] = useState([]);

    useEffect(() => {
        const fetchCartData = async () => {
            try {
                const { data, error } = await api({ action: "getCart" });
                if (error) {
                    console.error(error);
                } else {
                    setCartData(data.map(obj => ({
                        ...obj,
                        checked: true,
                        unit_price: obj.total_price / obj.quantity,
                    })));
                }
            } catch (err) {
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        };
        fetchCartData();
    }, []);

    const handleAllCheckedChange = (e) => {
        const checked = e.target.checked;
        setCartData(cartData.map(item => ({ ...item, checked })));
    };

    const handleItemCheckedChange = (index, checked) => {
        setCartData(cartData.map((item, i) =>
            i === index ? { ...item, checked } : item
        ));
    };

    const handleQuantityChange = async (index, newQuantity) => {
        const item = cartData[index];
        if (newQuantity < 1) return;

        try {
            await api({
                action: "updateCart",
                query: `${item.cart_id}`,
                params: { quantity: newQuantity, options: item.options },
            });
            setCartData(cartData.map((item, i) =>
                i === index
                    ? {
                        ...item,
                        quantity: newQuantity,
                        total_price: item.unit_price * newQuantity,
                    }
                    : item
            ));
        } catch (err) {
            console.error("Failed to update quantity", err);
        }
    };

    const handleDeleteSelectedItems = async () => {
        const itemsToDelete = cartData.filter(item => item.checked);
        try {
            await Promise.all(itemsToDelete.map(item =>
                api({
                    action: "removeCart",
                    query: `/${item.cart_id}`,
                })
            ));
            setCartData(cartData.filter(item => !item.checked));
        } catch (err) {
            console.error("Failed to delete items", err);
        }
    };

    const calculateTotalPrice = () => {
        return cartData
            .filter(item => item.checked)
            .reduce((total, item) => total + item.total_price, 0);
    };

    const totalCartPrice = calculateTotalPrice();
    const isFreeShipping = totalCartPrice >= FREE_SHIPPING_THRESHOLD;
    const shippingFee = isFreeShipping ? 0 : SHIPPING_COST;

    return (
        <div className="max-w-2xl mx-auto min-h-screen p-6 mb-20">
            <h1 className="text-left text-2xl font-semibold mb-10">
                CART ({cartData.length})
            </h1>

            {cartData.length > 0 && (
                <div className="flex justify-between items-center mb-4 pb-4 border-b">
                    <div className="text-sm font-medium">전체 선택</div>
                    <input
                        type="checkbox"
                        className="h-4 w-4"
                        checked={cartData.length > 0 && cartData.every(item => item.checked)}
                        onChange={handleAllCheckedChange}
                    />
                </div>
            )}

            {isLoading ? (
                <div>Loading...</div>
            ) : (
                <>
                    {cartData.length > 0 ? (
                        <>
                            {cartData.map((cart, index) => {
                                const { item, options, quantity, total_price: totalPrice, checked } = cart;
                                const { item_name: itemName, image_urls: images } = item;

                                return (
                                    <div key={index} className="border-b pb-4 mt-4 flex items-start">
                                        <img
                                            src={images[0]}
                                            alt={itemName}
                                            className="w-28 h-28 object-cover rounded"
                                        />
                                        <div className="ml-4 flex-1">
                                            <p className="font-medium">{itemName}</p>
                                            <p className="text-sm">
                                                {options}
                                            </p>
                                            <p className="text-sm text-gray-600 mb-2">
                                                ₩ {totalPrice.toLocaleString('ko-KR')}
                                            </p>

                                            <div className="flex items-center space-x-2">
                                                <button
                                                    className="px-2 w-7 bg-gray-100 text-gray-700 rounded border border-gray-300"
                                                    onClick={() => handleQuantityChange(index, quantity - 1)}
                                                    disabled={quantity <= 1}
                                                >
                                                    -
                                                </button>
                                                <span className="text-center w-5">{quantity}</span>
                                                <button
                                                    className="px-2 w-7 bg-gray-100 text-gray-700 rounded border border-gray-300"
                                                    onClick={() => handleQuantityChange(index, quantity + 1)}
                                                >
                                                    +
                                                </button>
                                            </div>
                                        </div>
                                        <div className="flex items-center ml-4">
                                            <input
                                                type="checkbox"
                                                className="h-4 w-4"
                                                checked={checked}
                                                onChange={() => handleItemCheckedChange(index, !checked)}
                                            />
                                        </div>
                                    </div>
                                );
                            })}

                            <div className="flex justify-end pb-10 my-4 border-b">
                                <button className="bg-gray-300 py-2 px-5 rounded"
                                    onClick={handleDeleteSelectedItems}
                                >
                                    삭제
                                </button>
                            </div>

                            <div className="text-center text-sm text-gray-600 my-8">
                                ₩{totalCartPrice.toLocaleString('ko-KR')} + 배송비 {isFreeShipping ? "₩0" : `₩${SHIPPING_COST.toLocaleString('ko-KR')}`} <br />
                                {/* {FREE_SHIPPING_THRESHOLD.toLocaleString('ko-KR')}원 이상 구매 시 배송비 무료 */}
                                배송비는 항상 무료
                            </div>

                            <div className="border-t pt-8">
                                <div className="flex justify-between mb-2">
                                    <span>상품 합계</span>
                                    <span>₩ {totalCartPrice.toLocaleString('ko-KR')}</span>
                                </div>
                                <div className="flex justify-between mb-4">
                                    <span>배송비</span>
                                    <span>₩ {shippingFee.toLocaleString('ko-KR')}</span>
                                </div>
                                <div className="flex justify-between font-semibold text-lg border-t pt-4">
                                    <span>합계</span>
                                    <span>₩ {(totalCartPrice + shippingFee).toLocaleString('ko-KR')}</span>
                                </div>
                            </div>

                            <button className="w-full bg-slate-700 text-white py-3 rounded mt-10"
                                onClick={() => {
                                    const filteredCartData = cartData.filter(item => item.checked);
                                    navigate('/order', {
                                        state: {
                                            _cartData: filteredCartData,
                                            orderType: 'cart'
                                        }
                                    });
                                }}
                            >
                                주문하기
                            </button>
                        </>
                    ) : (
                        <div className="flex h-48 justify-center border-b">
                            <div className="flex flex-col justify-center">
                                장바구니가 비어있습니다.
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default Cart;
